var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('van-cell-group', {
          staticClass: "cell-group form"
        }, [_c('van-cell', {
          staticClass: "picker",
          attrs: {
            "title": "盘点区域",
            "required": ""
          },
          on: {
            "click": _vm.onSpaceSelect
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "pickerText",
                class: {
                  disabled: _vm.spaceFullCode === ''
                }
              }, [_vm._v(_vm._s(_vm.spaceFullName || _vm.spaceFullCode || '请选择') + " "), _c('van-icon', {
                attrs: {
                  "name": "arrow"
                }
              })], 1)];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "footer"
        }, [_c('van-button', {
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.onNext
          }
        }, [_vm._v("下一步")])], 1)];
      },
      proxy: true
    }])
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.spaceListShow,
      callback: function ($$v) {
        _vm.spaceListShow = $$v;
      },
      expression: "spaceListShow"
    }
  }, [_c('van-picker', {
    attrs: {
      "show-toolbar": "",
      "value-key": "spaceFullName",
      "columns": _vm.spaceList
    },
    on: {
      "confirm": _vm.onSelectSpace,
      "cancel": function ($event) {
        _vm.spaceListShow = false;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };