import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
export default {
  name: 'AssetsCheckSelect',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  data() {
    return {
      spaceFullCode: '',
      spaceFullName: '',
      spaceType: '',
      spaceListShow: false,
      spaceList: []
    };
  },
  methods: {
    onSpaceSelect() {
      let {
        dcProjectId
      } = this.projectInfo;
      assetsApi.getAllCangKuGongQuByProjectId(dcProjectId).then(res => {
        let list = res.data.data || [];
        this.spaceList = list;
        if (!this.spaceList.length) {
          this.$toast('暂无数据');
        } else {
          this.spaceListShow = true;
        }
      });
    },
    onSelectSpace(item) {
      this.spaceFullCode = item.spaceFullCode;
      this.spaceFullName = item.spaceFullName;
      this.spaceType = item.spaceType;
      this.spaceListShow = false;
    },
    onNext() {
      if (!this.spaceFullCode) {
        this.$toast('请选择盘点区域');
        return;
      }
      this.$router.push({
        name: 'AssetsCheckIndex',
        query: {
          projectId: this.projectInfo.dcProjectId,
          projectName: this.projectInfo.projectName,
          spaceFullCode: this.spaceFullCode,
          spaceFullName: this.spaceFullName,
          spaceType: this.spaceType
        }
      });
    }
  }
};